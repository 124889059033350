import React from "react"
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from "@material-ui/core/Typography"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import { navigate } from "gatsby"

const Topbar = () => {
  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton
          onClick={() => {
            navigate(-1)
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography style={{ fontFamily: "visby", fontWeight: "bold" }}>
          Help center
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar
